
import { defineComponent } from "vue";
import Table from "@components/table/Table.vue";
import Countup from "@components/Countup.vue";
import TableTag from "@components/table/components/TableTag.vue";
import TableStatus from "@components/table/components/TableStatus.vue";

export default defineComponent({
    name: "PickingJobView",
    components: { Countup, TableTag, TableStatus },
    data() {
        let ID = this.$route.params.jobId;
        if (ID !== ':123') {
            this.$router.push('/picking-and-packing/picking-jobs');
        }

        return {
            ID
        }
    }
});
