
import { defineComponent } from "vue";

export default defineComponent({
    name: "TableStatus",
    props: ['status'],
    setup(props) {
        const originUrl = window.location.origin;
        let statusName: string | undefined, color: string | undefined;

        switch (props.status) {
            case 0:
                statusName = 'Complete';
                color = "#F0FFF0";
                break;
            case 1:
                statusName = 'New';
                color = "#FFF000";
                break;
            case 2:
                statusName = 'In-Progress';
                color = "#FFF000";
                break;
            case 3:
                statusName = 'Partial';
                color = "#FFF0FF";
                break;
        }

        return {
            originUrl,
            statusName,
            color
        };
    },
});
