
import { defineComponent } from "vue";

export default defineComponent({
    name: "TableTag",
    props: ['tag'],
    setup(props) {
        const originUrl = window.location.origin;
        let tagName: string | undefined, color: string | undefined;

        switch (props.tag) {
            case 0:
                tagName = 'ASAP';
                color = "#F0FFF0";
                break;
            case 1:
                tagName = 'STOPPPPPPPPPPPPPPPPPPPPPPPP';
                color = "#F0F0F0";
                break;
            case 2:
                tagName = 'Check';
                color = "#F0F0F0";
                break;
            case 3:
                tagName = 'Out Of Stock';
                color = "#F0F0F0";
                break;
        }

        return {
            originUrl,
            tagName,
            color
        };
    },
});
