import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-a42420b0")
const _hoisted_1 = { class: "m-0 fs-6 text-overflow-ellipsis overflow-hidden" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_ctx.status && _ctx.color)
    ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        class: "tag d-flex align-items-center justify-content-between w-100 px-4 py-2 svg-icon svg-icon-2x svg-icon-blue",
        style: _normalizeStyle([{ 'backgroundColor': _ctx.color }])
      }, [
        _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.statusName), 1),
        _createVNode(_component_inline_svg, {
          class: "ms-2",
          src: `${_ctx.originUrl}/assets/svg/icons/actions/exit.svg`
        }, null, 8, ["src"])
      ], 4))
    : _createCommentVNode("", true)
}